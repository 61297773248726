import MessageForm from "@/components/MessageForm";

export default function Contact() {
  return (
    <section className="bg-white">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 ">
          <div className="mx-auto max-w-screen-sm text-center mb-8 lg:mb-16">
          </div> 
        <div>
          <MessageForm />
        </div>
      </div>
    </section>
  )
}
