type ProfileProps = {
  name: string;
  role: string;
  title: string;
  image: string;
};


export default function Profile({ name, role, title, image }: ProfileProps) {
  return (
    <div className="mx-auto md:pr-4 items-center justify-center bg-neutral-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
      <img 
        className="w-[225px] rounded-lg sm:rounded-none sm:rounded-l-lg" 
        src={image}
        alt={name}
      />
      <div className="pl-5 py-4">
        <h3 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
          <span>{name}</span>
        </h3>
        <p className="text-gray-500 dark:text-gray-400">{title}</p>
        <p className="text-gray-500 dark:text-gray-400">{role}</p>
      </div>
    </div>
  );
}
