import { APIProvider, AdvancedMarker, Map, Pin, useAdvancedMarkerRef } from '@vis.gl/react-google-maps';
import {useEffect, useState} from 'react';

type GoogleMapsWidgetProps = {
  apiKey: string;     // Google Maps API key
  mapId: string;      // ID for the map
  lat: number;        // Latitude for the map center
  lng: number;        // Longitude for the map center
  zoom: number;       // Zoom level for the map view
  position_lat: number; // Latitude for the marker position
  position_lng: number; // Longitude for the marker position
};

export default function GoogleMapsWidget({ apiKey, mapId, lat, lng, zoom, position_lat, position_lng }: GoogleMapsWidgetProps) {

  const [markerRef, marker] = useAdvancedMarkerRef();
  const [inited, setInited] = useState<boolean>(false);

  useEffect(() => {
    if (!marker) {
      return;
    }
  }, [marker]);

  useEffect(() => {
    if (!apiKey) {
      return;
    }
    setInited(true);
  }, [apiKey]);
  

  if (!inited) {
    return null;
  }

  return (
    <div className="flex container mx-auto">
      <APIProvider apiKey={ apiKey }>
        <Map
          mapId={ mapId }
          style={{width: '600px', height: '450px'}}
          defaultCenter={{lat: lat, lng: lng}}
          defaultZoom={zoom}
        >
          <AdvancedMarker ref={markerRef} position={{lat: position_lat, lng: position_lng}}>
            <Pin />
          </AdvancedMarker>
        </Map>
      </APIProvider>
    </div>
  );
}
