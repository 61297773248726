import { Routes, Route, Outlet } from "react-router-dom";
import { Toaster } from "@/components/ui/sonner"
import Home from "@/pages/Home";
import About from "@/pages/About";
import Privacy from "@/pages/Privacy";
import Footer from "@/components/Footer";
import Navbar from "@/components/Navbar";
import Contact from "@/pages/Contact";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="contact" element={<Contact />} />
      </Route>
    </Routes>
  );
}

function Layout() {
  return (
    <div>
      <Navbar />
      <Outlet />
      <Footer />
      <Toaster expand={true} />
    </div>
  );
}
