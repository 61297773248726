export default function HealthquestWidget() {
  return (
    <iframe 
      src={"https://cloud.healthquest.ca:45201/onlinebooking"} 
      style={{
        width: "450px",
        height: '500px',
        border: 'none'
      }}></iframe>
  )
}
