import PROFILE_JOEL from '@/assets/profile_joel_sanagustin.jpg';
import PROFILE_MARGARET from '@/assets/profile_margaret_querijero.jpg';
import PROFILE_CARLA from '@/assets/carla.jpg';
import PROFILE_JANETTE from '@/assets/janette.jpg';
import PROFILE_MICHELLE from '@/assets/michelle.jpg';
import Profile from '@/components/Profile';

export default function About() {

  const team = [
    {
      name: "Joel San Agustin",
      title: "BSN, MD, MRCGP, CCFP",
      role: "Family Physician",
      image: PROFILE_JOEL,
    },
    {
      name: "Margaret Querijero",
      title: "MD, MSc, FPPA",
      role: "Psychiatrist",
      image: PROFILE_MARGARET,
    },
    {
      name: "Janette San Agustin",
      title: "BSN, RN (U.K.)",
      role: "Practice Manager",
      image: PROFILE_JANETTE,
    },
    {
      name: "Carla",
      role: "Medical Office Assistant",
      image: PROFILE_CARLA,
    },
    {
      name: "Michelle",
      title: "BScN, RN",
      role: "Registered Nurse (ESPCN)",
      image: PROFILE_MICHELLE,
    },
  ];


  return (
    <section className="bg-white">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 ">
          <div className="mx-auto max-w-screen-sm text-center mb-8 lg:mb-16">
              <h2 className="my-3 text-4xl uppercase">
                our team
              </h2>
          </div> 
          <div className="grid gap-8 mb-6 lg:mb-16 md:grid-cols-2">
            {team.map((member, index) => (
              <Profile 
              key={index} 
              name={member.name} 
              role={member.role} 
              title={member.title}
              image={member.image} 
              />
            ))}
          </div>  
      </div>
    </section>
  );
}
