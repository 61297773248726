import LOGO_PATH from "@/assets/logo.png";
import {MenuIcon} from "lucide-react";
import {Link} from "react-router-dom";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"

export default function Navbar() {
  return (
    <header className="flex flex-col justify-center w-full bg-white max-md:max-w-full">

      <nav className="hidden sm:flex justify-center items-center px-16 w-full text-lg font-medium leading-6 text-teal-500 bg-white shadow-sm max-md:px-5 max-md:max-w-full">
        <div className="flex gap-5 justify-between items-center py-3 max-w-full w-[1080px] max-md:flex-wrap">
          <div className="xl:-ml-20 overflow-visible self-stretch my-auto">
            <span className="flex-col md:text-lg">Tel: (780) 757-0088</span>
          </div>
          <Link to="/" className="self-stretch my-auto">
            <img 
              loading="lazy" 
              src={ LOGO_PATH } 
              alt="Family Health Medical Centre Logo" 
              className="shrink-0 self-stretch aspect-[1.02] w-[51px]" 
            />
          </Link>
          <div className="self-stretch my-auto">
          <Link to="/about" className="mx-2">About</Link>
          <Link to="/contact" className="ml-2">Contact</Link>

          </div>
        </div>
      </nav>

      <nav className="sm:hidden px-4">
        <div className="flex items-center justify-between py-3" aria-label="Global">
          <Link to="/" className="flex">
            <img 
              loading="lazy" 
              src={ LOGO_PATH } 
              alt="Family Health Medical Centre Logo" 
              className="shrink-0 self-stretch aspect-[1.02] w-[51px]" 
            />
          </Link>

          <div className="flex">
            <span className="flex-col text-teal-500 font-medium text-base md:text-lg">
              Tel: (780) 757-0088
            </span>
          </div>

          <DropdownMenu>
            <DropdownMenuTrigger>
              <MenuIcon className="h-6 w-6 text-neutral-900 " aria-hidden="true" />
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem>
                <Link to="/about" className="self-stretch my-auto">About</Link>
              </DropdownMenuItem>
              <DropdownMenuItem>
                <Link to="/contact" className="self-stretch my-auto">Contact</Link>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </nav>

    </header>
  );
}
