import ESPCN_LOGO_ASSET_PATH from "@/assets/escpcn.png";

const ESPCN_LOGO_PATH = ESPCN_LOGO_ASSET_PATH;

export default function Footer() {
  return(
      <footer className="items-center pt-8 w-full text-2xl font-light leading-6 text-center bg-neutral-100 text-zinc-800 tracking-[3.45px] max-md:max-w-full">
          <span className="uppercase">Proud Member of </span>
          <div className="flex justify-center py-3 px-16">
            <img loading="lazy" src={ ESPCN_LOGO_PATH } alt="ESPCN Logo" />
          </div>
          <div className="flex justify-center py-3 bg-neutral-900 text-neutral-200">
            <div className="flex gap-6 justify-center items-center py-3 max-w-full w-[1080px] max-md:flex-wrap">
              <span className="px-5 text-sm">Family Health Medical Centre</span>
            </div>
            <div className="flex gap-6 justify-center items-center py-3 max-w-full w-[1080px] max-md:flex-wrap">
              <a className="px-5 text-sm" href="/privacy">Privacy Policy</a>
            </div>
          </div>
      </footer>
  );
}
